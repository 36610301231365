<template>
    <div class="box">
        <div class="nav">销卡明细</div>
        <div class="uploadList" v-for="(item,index) in RecordList" :key="index">
            <img src="@/assets/images/oderUpload/icon.png" alt="">
            <p>{{item.userjoin.nickname}}</p>
            <p class="uploadList_item">上传成功</p>
            <p class="uploadList_time">{{item.createtime_text}}</p>
        </div>
    </div>
</template>
<script>
    import distubritionTwoApi from '@/untils/distubritionTwo.js'
export default{
    data(){
        return{
            RecordList:[],
        }
    },
    mounted(){
        this.info = JSON.parse(sessionStorage.getItem('UserInfo'))
        this.downRecord()
    },
    methods:{
        downRecord(){
            let data ={
                user_id:this.info.id
            }
            distubritionTwoApi.downRecord({data}).then(res=>{
                if(res.data.code == 200){
                    this.RecordList = res.data.data.data.data
                }
            })
        }
    }

}
</script>
<style scoped lang="less">
.box{
    min-height: 100vh;
    background-color: #F8F9FA;
    .nav {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        // text-align: center;
        justify-content: center;
    }
    .uploadList{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 72px;
        border-bottom: 1px solid #F3F3F3;
        font-size: 14px;
        .uploadList_item{
            color: #1D6CFC;
        }
        .uploadList_time{
            color: #1D6CFC;
            font-size: 12px;
        }
    }

}
</style>